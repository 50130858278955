import React from "react";
import "../../assests/css/privacy-policy.css"
import Footer from "../Footer";
import Header from "../Header";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full content-inner-2">
            <div className="container">
              <div className="section-head text-black text-center">
                <h2 className="text-center my-5 fw-bold">Privacy & Policy</h2>
              </div>
              <div className="c16 doc-content">
                <p className="c9"><span className="c1">This Privacy Policy describes Our policies and procedures on the collection, use and
                  disclosure of Your information when You use the Service and tells You about Your privacy rights and how the
                  law protects You.</span></p>
                <p className="c9"><span className="c1">We use Your Personal data to provide and improve the Service. By using the Service,
                  You agree to the collection and use of information in accordance with this Privacy Policy.</span></p>
                <p className="c6 title"><span className="c14">Interpretation and Definitions</span></p>
                <h2 className="c10"><span className="c4">Interpretation</span></h2>
                <p className="c9"><span className="c1">The words of which the initial letter is capitalized have meanings defined under the
                  following conditions. The following definitions shall have the same meaning regardless of whether they
                  appear in singular or in plural.</span></p>
                <h2 className="c10"><span className="c4">Definitions</span></h2>
                <p className="c9"><span className="c1">For the purposes of this Privacy Policy:</span></p>
                <p className="c0"><span className="c8">Account</span><span className="c1">&nbsp;means a unique account created for You to access
                  our Service or parts of our Service.</span></p>
                <p className="c0"><span className="c8">Affiliate</span><span className="c1">&nbsp;means an entity that controls, is controlled
                  by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the
                  shares, equity interest or other securities entitled to vote for election of directors or other managing
                  authority.</span></p>
                <p className="c0"><span className="c8">Application</span><span className="c1">&nbsp;means the software program provided by the
                  Company downloaded by You on any electronic device, named Dice Fanatics Collector </span></p>
                <p className="c0"><span className="c8">Business</span><span className="c1">, for the purpose of the CCPA (California Consumer
                  Privacy Act), refers to the Company as the legal entity that collects Consumers&#39; personal information
                  and determines the purposes and means of the processing of Consumers&#39; personal information, or on behalf
                  of which such information is collected and that alone, or jointly with others, determines the purposes and
                  means of the processing of consumers&#39; personal information, that does business in the State of
                  California.</span></p>
                <p className="c0"><span className="c8">Company</span><span className="c1">&nbsp;(referred to as either &quot;the Company&quot;,
                  &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to </span><span
                    className="c19">WOGD LLC 30 North Gould Street, Suite 22123, Sheridan WY, 82801</span></p>
                <p className="c0"><span className="c8">Consumer</span><span className="c1">, for the purpose of the CCPA (California Consumer
                  Privacy Act), means a natural person who is a California resident. A resident, as defined in the law,
                  includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2)
                  every individual who is domiciled in the USA who is outside the USA for a temporary or transitory
                  purpose.</span></p>
                <p className="c0"><span className="c8">Country</span><span className="c1">&nbsp;refers to: United States</span></p>
                <p className="c0"><span className="c8">Device</span><span className="c1">&nbsp;means any device that can access the Service such
                  as a computer, a cellphone or a digital tablet.</span></p>
                <p className="c0"><span className="c8">Do Not Track</span><span className="c1">&nbsp;(DNT) is a concept that has been promoted
                  by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet
                  industry to develop and implement a mechanism for allowing internet users to control the tracking of their
                  online activities across websites.</span></p>
                <p className="c0"><span className="c8">Personal Data</span><span className="c1">&nbsp;is any information that relates to an
                  identified or identifiable individual.</span></p>
                <p className="c0"><span className="c1">For the purposes of the CCPA, Personal Data means any information that identifies,
                  relates to, describes or is capable of being associated with, or could reasonably be linked, directly or
                  indirectly, with You.</span></p>
                <p className="c0"><span className="c8">Sale</span><span className="c1">, for the purpose of the CCPA (California Consumer
                  Privacy Act), means selling, renting, releasing, disclosing, disseminating, making available, transferring,
                  or otherwise communicating orally, in writing, or by electronic or other means, a Consumer&#39;s personal
                  information to another business or a third party for monetary or other valuable consideration.</span></p>
                <p className="c0"><span className="c8">Service</span><span className="c1">&nbsp;refers to the Application.</span></p>
                <p className="c0"><span className="c8">Service Provider</span><span className="c1">&nbsp;means any natural or legal person who
                  processes the data on behalf of the Company. It refers to third-party companies or individuals employed by
                  the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services
                  related to the Service or to assist the Company in analyzing how the Service is used.</span></p>
                <p className="c0"><span className="c8">Third-party Social Media Service</span><span className="c1">&nbsp;refers to any website
                  or any social network website through which a User can log in or create an account to use the
                  Service.</span></p>
                <p className="c0"><span className="c8">Usage Data</span><span className="c1">&nbsp;refers to data collected automatically,
                  either generated by the use of the Service or from the Service infrastructure itself (for example, the
                  duration of a page visit).</span></p>
                <p className="c9 c13"><span className="c8">You</span><span className="c1">&nbsp;means the individual accessing or using the
                  Service, or the company, or other legal entity on behalf of which such individual is accessing or using the
                  Service, as applicable.</span></p>
                <p className="c6 title"><span className="c14">Collecting and Using Your Personal Data</span></p>
                <h2 className="c10"><span className="c4">Types of Data Collected</span></h2>
                <h3 className="c10"><span className="c2">Personal Data</span></h3>
                <p className="c9"><span className="c1">While using Our Service, We may ask You to provide Us with certain personally
                  identifiable information that can be used to contact or identify You. Personally identifiable information
                  may include, but is not limited to:</span></p>
                <p className="c0"><span className="c1">Email address</span></p>
                <p className="c0"><span className="c1">First name and last name</span></p>
                <p className="c0"><span className="c1">Phone number</span></p>
                <p className="c0"><span className="c1">Address, State, Province, ZIP/Postal code, City</span></p>
                <p className="c9 c13"><span className="c1">Usage Data</span></p>
                <h3 className="c10"><span className="c2">Usage Data</span></h3>
                <p className="c9"><span className="c1">Usage Data is collected automatically when using the Service.</span></p>
                <p className="c9"><span className="c1">Usage Data may include information such as Your Device&#39;s Internet Protocol
                  address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time
                  and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic
                  data.</span></p>
                <p className="c9"><span className="c1">When You access the Service by or through a mobile device, We may collect certain
                  information automatically, including, but not limited to, the type of mobile device You use, Your mobile
                  device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile
                  Internet browser You use, unique device identifiers and other diagnostic data.</span></p>
                <p className="c9"><span className="c1">We may also collect information that Your browser sends whenever You visit our
                  Service or when You access the Service by or through a mobile device.</span></p>
                <h3 className="c10"><span className="c2">Information from Third-Party Social Media Services</span></h3>
                <p className="c9"><span className="c1">The Company allows You to create an account and log in to use the Service through the
                  following Third-party Social Media Services:</span></p>
                <ul className="c3 lst-kix_list_1-0 start">
                  <li className="c7 c5 li-bullet-0"><span className="c1">Google</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Facebook</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Twitter</span></li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c1">LinkedIn</span></li>
                </ul>
                <p className="c9"><span className="c1">If You decide to register through or otherwise grant us access to a Third-Party
                  Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social
                  Media Service&#39;s account, such as Your name, Your email address, Your activities or Your contact list
                  associated with that account.</span></p>
                <p className="c9"><span className="c1">You may also have the option of sharing additional information with the Company
                  through Your Third-Party Social Media Service&#39;s account. If You choose to provide such information and
                  Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and
                  store it in a manner consistent with this Privacy Policy.</span></p>
                <h3 className="c10"><span className="c2">Information Collected while Using the Application</span></h3>
                <p className="c9"><span className="c1">While using Our Application, in order to provide features of Our Application, We may
                  collect, with Your prior permission:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c1">Information regarding your location</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Information from your Device&#39;s phone book (contacts
                    list)</span></li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c1">Pictures and other information from your Device&#39;s camera
                    and photo library</span></li>
                </ul>
                <p className="c9"><span className="c1">We use this information to provide features of Our Service, to improve and customize
                  Our Service. The information may be uploaded to the Company&#39;s servers and/or a Service Provider&#39;s
                  server or it may be simply stored on Your device.</span></p>
                <p className="c9"><span className="c1">You can enable or disable access to this information at any time, through Your Device
                  settings.</span></p>
                <h2 className="c10"><span className="c4">Use of Your Personal Data</span></h2>
                <p className="c9"><span className="c1">The Company may use Personal Data for the following purposes:</span></p>
                <p className="c0"><span className="c8">To provide and maintain our Service</span><span className="c1">, including to monitor the
                  usage of our Service.</span></p>
                <p className="c0"><span className="c8">To manage Your Account:</span><span className="c1">&nbsp;to manage Your registration as a
                  user of the Service. The Personal Data You provide can give You access to different functionalities of the
                  Service that are available to You as a registered user.</span></p>
                <p className="c0"><span className="c8">For the performance of a contract:</span><span className="c1">&nbsp;the development,
                  compliance and undertaking of the purchase contract for the products, items or services You have purchased
                  or of any other contract with Us through the Service.</span></p>
                <p className="c0"><span className="c8">To contact You:</span><span className="c1">&nbsp;To contact You by email, telephone
                  calls, SMS, or other equivalent forms of electronic communication, such as a mobile application&#39;s push
                  notifications regarding updates or informative communications related to the functionalities, products or
                  contracted services, including the security updates, when necessary or reasonable for their
                  implementation.</span></p>
                <p className="c0"><span className="c8">To provide You</span><span className="c1">&nbsp;with news, special offers and general
                  information about other goods, services and events which we offer that are similar to those that you have
                  already purchased or enquired about unless You have opted not to receive such information.</span></p>
                <p className="c0"><span className="c8">To manage Your requests:</span><span className="c1">&nbsp;To attend and manage Your
                  requests to Us.</span></p>
                <p className="c0"><span className="c8">For business transfers:</span><span className="c1">&nbsp;We may use Your information to
                  evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or
                  transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or
                  similar proceeding, in which Personal Data held by Us about our Service users is among the assets
                  transferred.</span></p>
                <p className="c9 c13"><span className="c8">For other purposes</span><span className="c1">: We may use Your information for other
                  purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional
                  campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</span>
                </p>
                <p className="c9"><span className="c1">We may share Your personal information in the following situations:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c8">With Service Providers:</span><span className="c1">&nbsp;We may share
                    Your personal information with Service Providers to monitor and analyze the use of our Service, for
                    payment processing, to contact You.</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c8">For business transfers:</span><span className="c1">&nbsp;We may share
                    or transfer Your personal information in connection with, or during negotiations of, any merger, sale of
                    Company assets, financing, or acquisition of all or a portion of Our business to another company.</span>
                  </li>
                  <li className="c7 c5 li-bullet-0"><span className="c8">With Affiliates:</span><span className="c1">&nbsp;We may share Your
                    information with Our affiliates, in which case we will require those affiliates to honor this Privacy
                    Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or
                    other companies that We control or that are under common control with Us.</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c8">With business partners:</span><span className="c1">&nbsp;We may share
                    Your information with Our business partners to offer You certain products, services or
                    promotions.</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c8">With other users:</span><span className="c1">&nbsp;when You share
                    personal information or otherwise interact in the public areas with other users, such information may be
                    viewed by all users and may be publicly distributed outside. If You interact with other users or
                    register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media
                    Service may see Your name, profile, pictures and description of Your activity. Similarly, other users
                    will be able to view descriptions of Your activity, communicate with You and view Your profile.</span>
                  </li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c8">With Your consent</span><span className="c1">: We may disclose
                    Your personal information for any other purpose with Your consent.</span></li>
                </ul>
                <h2 className="c10"><span className="c4">Retention of Your Personal Data</span></h2>
                <p className="c9"><span className="c1">The Company will retain Your Personal Data only for as long as is necessary for the
                  purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary
                  to comply with our legal obligations (for example, if we are required to retain your data to comply with
                  applicable laws), resolve disputes, and enforce our legal agreements and policies.</span></p>
                <p className="c9"><span className="c1">The Company will also retain Usage Data for internal analysis purposes. Usage Data is
                  generally retained for a shorter period of time, except when this data is used to strengthen the security or
                  to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time
                  periods.</span></p>
                <h2 className="c10"><span className="c4">Transfer of Your Personal Data</span></h2>
                <p className="c9"><span className="c1">Your information, including Personal Data, is processed at the Company&#39;s
                  operating offices and in any other places where the parties involved in the processing are located. It means
                  that this information may be transferred to &mdash; and maintained on &mdash; computers located outside of
                  Your state, province, country or other governmental jurisdiction where the data protection laws may differ
                  than those from Your jurisdiction.</span></p>
                <p className="c9"><span className="c1">Your consent to this Privacy Policy followed by Your submission of such information
                  represents Your agreement to that transfer.</span></p>
                <p className="c9"><span className="c1">The Company will take all steps reasonably necessary to ensure that Your data is
                  treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take
                  place to an organization or a country unless there are adequate controls in place including the security of
                  Your data and other personal information.</span></p>
                <h2 className="c10"><span className="c4">Disclosure of Your Personal Data</span></h2>
                <h3 className="c10"><span className="c2">Business Transactions</span></h3>
                <p className="c9"><span className="c1">If the Company is involved in a merger, acquisition or asset sale, Your Personal Data
                  may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a
                  different Privacy Policy.</span></p>
                <h3 className="c10"><span className="c2">Law enforcement</span></h3>
                <p className="c9"><span className="c1">Under certain circumstances, the Company may be required to disclose Your Personal
                  Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a
                  government agency).</span></p>
                <h3 className="c10"><span className="c2">Other legal requirements</span></h3>
                <p className="c9"><span className="c1">The Company may disclose Your Personal Data in the good faith belief that such action
                  is necessary to:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c1">Comply with a legal obligation</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Protect and defend the rights or property of the Company</span>
                  </li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Prevent or investigate possible wrongdoing in connection with the
                    Service</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Protect the personal safety of Users of the Service or the
                    public</span></li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c1">Protect against legal liability</span></li>
                </ul>
                <h2 className="c10"><span className="c4">Security of Your Personal Data</span></h2>
                <p className="c9"><span className="c1">The security of Your Personal Data is important to Us, but remember that no method of
                  transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use
                  commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute
                  security.</span></p>
                <p className="c6 title"><span className="c14">Detailed Information on the Processing of Your Personal Data</span></p>
                <p className="c9"><span className="c1">The Service Providers We use may have access to Your Personal Data. These third-party
                  vendors collect, store, use, process and transfer information about Your activity on Our Service in
                  accordance with their Privacy Policies.</span></p>
                <h2 className="c10"><span className="c4">Analytics</span></h2>
                <p className="c9"><span className="c1">We may use third-party Service providers to monitor and analyze the use of our
                  Service.</span></p>
                <p className="c0"><span className="c8">Google Analytics</span></p>
                <p className="c0"><span className="c1">Google Analytics is a web analytics service offered by Google that tracks and reports
                  website traffic. Google uses the data collected to track and monitor the use of our Service. This data is
                  shared with other Google services. Google may use the collected data to contextualize and personalize the
                  ads of its own advertising network.</span></p>
                <p className="c0"><span className="c1">You may opt-out of certain Google Analytics features through your mobile device
                  settings, such as your device advertising settings or by following the instructions provided by Google in
                  their Privacy Policy: </span><span className="c1"><a className="c11"
                    href="https://www.google.com/url?q=https://policies.google.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1673855743413662&amp;usg=AOvVaw06y3qf-5qSZmwnWTZQqBKx">https://policies.google.com/privacy</a></span>
                </p>
                <p className="c0"><span className="c1">For more information on the privacy practices of Google, please visit the Google
                  Privacy &amp; Terms web page: </span><span className="c1"><a className="c11"
                    href="https://www.google.com/url?q=https://policies.google.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1673855743413961&amp;usg=AOvVaw0XxChtk_Z1s5vmB8RDiXEO">https://policies.google.com/privacy</a></span>
                </p>
                <p className="c0"><span className="c8">mapbox</span></p>
                <p className="c9 c13"><span className="c1">Their Privacy Policy can be viewed at </span><span className="c1"><a className="c11"
                  href="https://www.google.com/url?q=https://www.mapbox.com/legal/privacy&amp;sa=D&amp;source=editors&amp;ust=1673855743414213&amp;usg=AOvVaw3Dx7KInfK-hLEdSvNweNUm">https://www.mapbox.com/legal/privacy</a></span>
                </p>
                <h2 className="c10"><span className="c4">Payments</span></h2>
                <p className="c9"><span className="c1">We may provide paid products and/or services within the Service. In that case, we may
                  use third-party services for payment processing (e.g. payment processors).</span></p>
                <p className="c9"><span className="c1">We will not store or collect Your payment card details. That information is provided
                  directly to Our third-party payment processors whose use of Your personal information is governed by their
                  Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI
                  Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and
                  Discover. PCI-DSS requirements help ensure the secure handling of payment information.</span></p>
                <p className="c0"><span className="c8">Apple Store In-App Payments</span></p>
                <p className="c0"><span className="c1">Their Privacy Policy can be viewed at </span><span className="c1"><a className="c11"
                  href="https://www.google.com/url?q=https://www.apple.com/legal/privacy/en-ww/&amp;sa=D&amp;source=editors&amp;ust=1673855743414672&amp;usg=AOvVaw2QwwicEVVJQ2Lf9Twfdmor">https://www.apple.com/legal/privacy/en-ww/</a></span>
                </p>
                <p className="c0"><span className="c8">Google Play In-App Payments</span></p>
                <p className="c0"><span className="c1">Their Privacy Policy can be viewed at </span><span className="c1"><a className="c11"
                  href="https://www.google.com/url?q=https://www.google.com/policies/privacy/&amp;sa=D&amp;source=editors&amp;ust=1673855743414914&amp;usg=AOvVaw2lmgPygVn9iZkABmIp9zRn">https://www.google.com/policies/privacy/</a></span>
                </p>
                <p className="c0"><span className="c8">Stripe</span></p>
                <p className="c9 c13"><span className="c1">Their Privacy Policy can be viewed at </span><span className="c1"><a className="c11"
                  href="https://www.google.com/url?q=https://stripe.com/us/privacy&amp;sa=D&amp;source=editors&amp;ust=1673855743415164&amp;usg=AOvVaw0vGsnNJrRzcZvCguOK5hrb">https://stripe.com/us/privacy</a></span>
                </p>
                <p className="c6 title"><span className="c14">CCPA Privacy</span></p>
                <p className="c9"><span className="c1">This privacy notice section for California residents supplements the information
                  contained in Our Privacy Policy and it applies solely to all visitors, users, and others who reside in the
                  State of California.</span></p>
                <h2 className="c10"><span className="c4">Categories of Personal Information Collected</span></h2>
                <p className="c9"><span className="c1">We collect information that identifies, relates to, describes, references, is capable
                  of being associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer
                  or Device. The following is a list of categories of personal information which we may collect or may have
                  been collected from California residents within the last twelve (12) months.</span></p>
                <p className="c9"><span className="c1">Please note that the categories and examples provided in the list below are those
                  defined in the CCPA. This does not mean that all examples of that category of personal information were in
                  fact collected by Us, but reflects our good faith belief to the best of our knowledge that some of that
                  information from the applicable category may be and may have been collected. For example, certain categories
                  of personal information would only be collected if You provided such personal information directly to
                  Us.</span></p>
                <p className="c0"><span className="c8">Category A: Identifiers.</span></p>
                <p className="c0"><span className="c1">Examples: A real name, alias, postal address, unique personal identifier, online
                  identifier, Internet Protocol address, email address, account name, driver&#39;s license number, passport
                  number, or other similar identifiers.</span></p>
                <p className="c0"><span className="c1">Collected: Yes.</span></p>
                <p className="c0"><span className="c8">Category B: Personal information categories listed in the California Customer Records
                  statute (Cal. Civ. Code &sect; 1798.80(e)).</span></p>
                <p className="c0"><span className="c1">Examples: A name, signature, Social Security number, physical characteristics or
                  description, address, telephone number, passport number, driver&#39;s license or state identification card
                  number, insurance policy number, education, employment, employment history, bank account number, credit card
                  number, debit card number, or any other financial information, medical information, or health insurance
                  information. Some personal information included in this category may overlap with other categories.</span>
                </p>
                <p className="c0"><span className="c1">Collected: Yes.</span></p>
                <p className="c0"><span className="c8">Category C: Protected classification characteristics under California or federal
                  law.</span></p>
                <p className="c0"><span className="c1">Examples: Age (40 years or older), race, color, ancestry, national origin,
                  citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex
                  (including gender, gender identity, gender expression, pregnancy or childbirth and related medical
                  conditions), sexual orientation, veteran or military status, genetic information (including familial genetic
                  information).</span></p>
                <p className="c0"><span className="c1">Collected: No.</span></p>
                <p className="c0"><span className="c8">Category D: Commercial information.</span></p>
                <p className="c0"><span className="c1">Examples: Records and history of products or services purchased or considered.</span>
                </p>
                <p className="c0"><span className="c1">Collected: Yes.</span></p>
                <p className="c0"><span className="c8">Category E: Biometric information.</span></p>
                <p className="c0"><span className="c1">Examples: Genetic, physiological, behavioral, and biological characteristics, or
                  activity patterns used to extract a template or other identifier or identifying information, such as,
                  fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical
                  patterns, and sleep, health, or exercise data.</span></p>
                <p className="c0"><span className="c1">Collected: No.</span></p>
                <p className="c0"><span className="c8">Category F: Internet or other similar network activity.</span></p>
                <p className="c0"><span className="c1">Examples: Interaction with our Service or advertisement.</span></p>
                <p className="c0"><span className="c1">Collected: Yes.</span></p>
                <p className="c0"><span className="c8">Category G: Geolocation data.</span></p>
                <p className="c0"><span className="c1">Examples: Approximate physical location.</span></p>
                <p className="c0"><span className="c1">Collected: Yes.</span></p>
                <p className="c0"><span className="c8">Category H: Sensory data.</span></p>
                <p className="c0"><span className="c1">Examples: Audio, electronic, visual, thermal, olfactory, or similar
                  information.</span></p>
                <p className="c0"><span className="c1">Collected: No.</span></p>
                <p className="c0"><span className="c8">Category I: Professional or employment-related information.</span></p>
                <p className="c0"><span className="c1">Examples: Current or past job history or performance evaluations.</span></p>
                <p className="c0"><span className="c1">Collected: No.</span></p>
                <p className="c0"><span className="c8">Category J: Non-public education information (per the Family Educational Rights and
                  Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</span></p>
                <p className="c0"><span className="c1">Examples: Education records directly related to a student maintained by an
                  educational institution or party acting on its behalf, such as grades, transcripts, class lists, student
                  schedules, student identification codes, student financial information, or student disciplinary
                  records.</span></p>
                <p className="c0"><span className="c1">Collected: No.</span></p>
                <p className="c0"><span className="c8">Category K: Inferences drawn from other personal information.</span></p>
                <p className="c0"><span className="c1">Examples: Profile reflecting a person&#39;s preferences, characteristics,
                  psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</span>
                </p>
                <p className="c9 c13"><span className="c1">Collected: No.</span></p>
                <p className="c9"><span className="c1">Under CCPA, personal information does not include:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c1">Publicly available information from government records</span>
                  </li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Deidentified or aggregated consumer information</span></li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c1">Information excluded from the CCPA&#39;s scope, such as:
                  </span></li>
                </ul>
                <ul className="c3 lst-kix_list_2-0 start">
                  <li className="c0 c5 li-bullet-0"><span className="c1">Health or medical information covered by the Health Insurance
                    Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical
                    Information Act (CMIA) or clinical trial data</span></li>
                  <li className="c9 c5 c13 li-bullet-0"><span className="c1">Personal Information covered by certain sector-specific
                    privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or
                    California Financial Information Privacy Act (FIPA), and the Driver&#39;s Privacy Protection Act of
                    1994</span></li>
                </ul>
                <h2 className="c10"><span className="c4">Sources of Personal Information</span></h2>
                <p className="c9"><span className="c1">We obtain the categories of personal information listed above from the following
                  categories of sources:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c8">Directly from You</span><span className="c1">. For example, from the
                    forms You complete on our Service, preferences You express or provide through our Service, or from Your
                    purchases on our Service.</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c8">Indirectly from You</span><span className="c1">. For example, from
                    observing Your activity on our Service.</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c8">Automatically from You</span><span className="c1">. For example,
                    through cookies We or our Service Providers set on Your Device as You navigate through our
                    Service.</span></li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c8">From Service Providers</span><span className="c1">. For example,
                    third-party vendors to monitor and analyze the use of our Service, third-party vendors for payment
                    processing, or other third-party vendors that We use to provide the Service to You.</span></li>
                </ul>
                <h2 className="c10"><span className="c4">Use of Personal Information for Business Purposes or Commercial Purposes</span>
                </h2>
                <p className="c9"><span className="c1">We may use or disclose personal information We collect for &quot;business
                  purposes&quot; or &quot;commercial purposes&quot; (as defined under the CCPA), which may include the
                  following examples:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c1">To operate our Service and provide You with our Service.</span>
                  </li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">To provide You with support and to respond to Your inquiries,
                    including to investigate and address Your concerns and monitor and improve our Service.</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">To fulfill or meet the reason You provided the information. For
                    example, if You share Your contact information to ask a question about our Service, We will use that
                    personal information to respond to Your inquiry. If You provide Your personal information to purchase a
                    product or service, We will use that information to process Your payment and facilitate delivery.</span>
                  </li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">To respond to law enforcement requests and as required by
                    applicable law, court order, or governmental regulations.</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">As described to You when collecting Your personal information or
                    as otherwise set forth in the CCPA.</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">For internal administrative and auditing purposes.</span></li>
                  <li className="c9 c5 c12 li-bullet-0"><span className="c1">To detect security incidents and protect against malicious,
                    deceptive, fraudulent or illegal activity, including, when necessary, to prosecute those responsible for
                    such activities.</span></li>
                </ul>
                <p className="c9"><span className="c1">Please note that the examples provided above are illustrative and not intended to be
                  exhaustive. For more details on how we use this information, please refer to the &quot;Use of Your Personal
                  Data&quot; section.</span></p>
                <p className="c9"><span className="c1">If We decide to collect additional categories of personal information or use the
                  personal information We collected for materially different, unrelated, or incompatible purposes We will
                  update this Privacy Policy.</span></p>
                <h2 className="c10"><span className="c4">Disclosure of Personal Information for Business Purposes or Commercial
                  Purposes</span></h2>
                <p className="c9"><span className="c1">We may use or disclose and may have used or disclosed in the last twelve (12) months
                  the following categories of personal information for business or commercial purposes:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c1">Category A: Identifiers</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Category B: Personal information categories listed in the
                    California Customer Records statute (Cal. Civ. Code &sect; 1798.80(e))</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Category D: Commercial information</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Category F: Internet or other similar network activity</span>
                  </li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c1">Category G: Geolocation data</span></li>
                </ul>
                <p className="c9"><span className="c1">Please note that the categories listed above are those defined in the CCPA. This does
                  not mean that all examples of that category of personal information were in fact disclosed, but reflects our
                  good faith belief to the best of our knowledge that some of that information from the applicable category
                  may be and may have been disclosed.</span></p>
                <p className="c9"><span className="c1">When We disclose personal information for a business purpose or a commercial purpose,
                  We enter a contract that describes the purpose and requires the recipient to both keep that personal
                  information confidential and not use it for any purpose except performing the contract.</span></p>
                <h2 className="c10"><span className="c4">Sale of Personal Information</span></h2>
                <p className="c9"><span className="c1">As defined in the CCPA, &quot;sell&quot; and &quot;sale&quot; mean selling, renting,
                  releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in
                  writing, or by electronic or other means, a consumer&#39;s personal information by the business to a third
                  party for valuable consideration. This means that We may have received some kind of benefit in return for
                  sharing personal information, but not necessarily a monetary benefit.</span></p>
                <p className="c9"><span className="c1">Please note that the categories listed below are those defined in the CCPA. This does
                  not mean that all examples of that category of personal information were in fact sold, but reflects our good
                  faith belief to the best of our knowledge that some of that information from the applicable category may be
                  and may have been shared for value in return.</span></p>
                <p className="c9"><span className="c1">We may sell and may have sold in the last twelve (12) months the following categories
                  of personal information:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c5 c7 li-bullet-0"><span className="c1">Category A: Identifiers</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Category B: Personal information categories listed in the
                    California Customer Records statute (Cal. Civ. Code &sect; 1798.80(e))</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Category D: Commercial information</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Category F: Internet or other similar network activity</span>
                  </li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c1">Category G: Geolocation data</span></li>
                </ul>
                <h2 className="c10"><span className="c4">Share of Personal Information</span></h2>
                <p className="c9"><span className="c1">We may share Your personal information identified in the above categories with the
                  following categories of third parties:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c1">Service Providers</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Payment processors</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Our affiliates</span></li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">Our business partners</span></li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c1">Third party vendors to whom You or Your agents authorize Us
                    to disclose Your personal information in connection with products or services We provide to You</span>
                  </li>
                </ul>
                <h2 className="c10"><span className="c4">Sale of Personal Information of Minors Under 16 Years of Age</span></h2>
                <p className="c9"><span className="c1">We do not knowingly collect personal information from minors under the age of 16
                  through our Service, although certain third party websites that we link to may do so. These third-party
                  websites have their own terms of use and privacy policies and we encourage parents and legal guardians to
                  monitor their children&#39;s Internet usage and instruct their children to never provide information on
                  other websites without their permission.</span></p>
                <p className="c9"><span className="c1">We do not sell the personal information of Consumers We actually know are less than
                  16 years of age, unless We receive affirmative authorization (the &quot;right to opt-in&quot;) from either
                  the Consumer who is between 13 and 16 years of age, or the parent or guardian of a Consumer less than 13
                  years of age. Consumers who opt-in to the sale of personal information may opt-out of future sales at any
                  time. To exercise the right to opt-out, You (or Your authorized representative) may submit a request to Us
                  by contacting Us.</span></p>
                <p className="c9"><span className="c1">If You have reason to believe that a child under the age of 13 (or 16) has provided
                  Us with personal information, please contact Us with sufficient detail to enable Us to delete that
                  information.</span></p>
                <h2 className="c10"><span className="c4">Your Rights under the CCPA</span></h2>
                <p className="c9"><span className="c1">The CCPA provides California residents with specific rights regarding their personal
                  information. If You are a resident of California, You have the following rights:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c8">The right to notice.</span><span className="c1">&nbsp;You have the
                    right to be notified which categories of Personal Data are being collected and the purposes for which
                    the Personal Data is being used.</span></li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c8">The right to request.</span><span className="c1">&nbsp;Under
                    CCPA, You have the right to request that We disclose information to You about Our collection, use, sale,
                    disclosure for business purposes and share of personal information. Once We receive and confirm Your
                    request, We will disclose to You: </span></li>
                </ul>
                <ul className="c3 lst-kix_list_2-0">
                  <li className="c0 c5 li-bullet-0"><span className="c1">The categories of personal information We collected about
                    You</span></li>
                  <li className="c0 c5 li-bullet-0"><span className="c1">The categories of sources for the personal information We
                    collected about You</span></li>
                  <li className="c0 c5 li-bullet-0"><span className="c1">Our business or commercial purpose for collecting or selling that
                    personal information</span></li>
                  <li className="c0 c5 li-bullet-0"><span className="c1">The categories of third parties with whom We share that personal
                    information</span></li>
                  <li className="c0 c5 li-bullet-0"><span className="c1">The specific pieces of personal information We collected about
                    You</span></li>
                  <li className="c9 c13 c5 li-bullet-0"><span className="c1">If we sold Your personal information or disclosed Your
                    personal information for a business purpose, We will disclose to You: </span></li>
                </ul>
                <ul className="c3 lst-kix_list_3-0 start">
                  <li className="c18 c5 c20 li-bullet-0"><span className="c1">The categories of personal information categories
                    sold</span></li>
                  <li className="c9 c5 c18 li-bullet-0"><span className="c1">The categories of personal information categories
                    disclosed</span></li>
                </ul>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c8">The right to say no to the sale of Personal Data
                    (opt-out).</span><span className="c1">&nbsp;You have the right to direct Us to not sell Your personal
                      information. To submit an opt-out request please contact Us.</span></li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c8">The right to delete Personal Data.</span><span
                    className="c1">&nbsp;You have the right to request the deletion of Your Personal Data, subject to certain
                    exceptions. Once We receive and confirm Your request, We will delete (and direct Our Service Providers
                    to delete) Your personal information from our records, unless an exception applies. We may deny Your
                    deletion request if retaining the information is necessary for Us or Our Service Providers to: </span>
                  </li>
                </ul>
                <ul className="c3 lst-kix_list_2-0">
                  <li className="c0 c5 li-bullet-0"><span className="c1">Complete the transaction for which We collected the personal
                    information, provide a good or service that You requested, take actions reasonably anticipated within
                    the context of our ongoing business relationship with You, or otherwise perform our contract with
                    You.</span></li>
                  <li className="c0 c5 li-bullet-0"><span className="c1">Detect security incidents, protect against malicious, deceptive,
                    fraudulent, or illegal activity, or prosecute those responsible for such activities.</span></li>
                  <li className="c0 c5 li-bullet-0"><span className="c1">Debug products to identify and repair errors that impair existing
                    intended functionality.</span></li>
                  <li className="c0 c5 li-bullet-0"><span className="c1">Exercise free speech, ensure the right of another consumer to
                    exercise their free speech rights, or exercise another right provided for by law.</span></li>
                  <li className="c0 c5 li-bullet-0"><span className="c1">Comply with the California Electronic Communications Privacy Act
                    (Cal. Penal Code &sect; 1546 et. seq.).</span></li>
                  <li className="c0 c5 li-bullet-0"><span className="c1">Engage in public or peer-reviewed scientific, historical, or
                    statistical research in the public interest that adheres to all other applicable ethics and privacy
                    laws, when the information&#39;s deletion may likely render impossible or seriously impair the
                    research&#39;s achievement, if You previously provided informed consent.</span></li>
                  <li className="c0 c5 li-bullet-0"><span className="c1">Enable solely internal uses that are reasonably aligned with
                    consumer expectations based on Your relationship with Us.</span></li>
                  <li className="c0 c5 li-bullet-0"><span className="c1">Comply with a legal obligation.</span></li>
                  <li className="c9 c13 c5 li-bullet-0"><span className="c1">Make other internal and lawful uses of that information that
                    are compatible with the context in which You provided it.</span></li>
                </ul>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c9 c12 c5 li-bullet-0"><span className="c8">The right not to be discriminated against.</span><span
                    className="c1">&nbsp;You have the right not to be discriminated against for exercising any of Your
                    consumer&#39;s rights, including by: </span></li>
                </ul>
                <ul className="c3 lst-kix_list_2-0">
                  <li className="c0 c5 li-bullet-0"><span className="c1">Denying goods or services to You</span></li>
                  <li className="c0 c5 li-bullet-0"><span className="c1">Charging different prices or rates for goods or services,
                    including the use of discounts or other benefits or imposing penalties</span></li>
                  <li className="c0 c5 li-bullet-0"><span className="c1">Providing a different level or quality of goods or services to
                    You</span></li>
                  <li className="c9 c13 c5 li-bullet-0"><span className="c1">Suggesting that You will receive a different price or rate
                    for goods or services or a different level or quality of goods or services</span></li>
                </ul>
                <h2 className="c10"><span className="c4">Exercising Your CCPA Data Protection Rights</span></h2>
                <p className="c9"><span className="c1">In order to exercise any of Your rights under the CCPA, and if You are a California
                  resident, You can contact Us:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c1">By email: </span><span className="c17"><a className="c11"
                    href="mailto:support@wogd.com">info@dicefanatics.com</a></span></li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c1">Only You, or a person registered with the California
                    Secretary of State that You authorize to act on Your behalf, may make a verifiable request related to
                    Your personal information.</span></li>
                </ul>
                <p className="c9"><span className="c1">Your request to Us must:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c1">Provide sufficient information that allows Us to reasonably
                    verify You are the person about whom We collected personal information or an authorized
                    representative</span></li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c1">Describe Your request with sufficient detail that allows Us
                    to properly understand, evaluate, and respond to it</span></li>
                </ul>
                <p className="c9"><span className="c1">We cannot respond to Your request or provide You with the required information if We
                  cannot:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c1">Verify Your identity or authority to make the request</span></li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c1">And confirm that the personal information relates to
                    You</span></li>
                </ul>
                <p className="c9"><span className="c1">We will disclose and deliver the required information free of charge within 45 days
                  of receiving Your verifiable request. The time period to provide the required information may be extended
                  once by an additional 45 days when reasonably necessary and with prior notice.</span></p>
                <p className="c9"><span className="c1">Any disclosures We provide will only cover the 12-month period preceding the
                  verifiable request&#39;s receipt.</span></p>
                <p className="c9"><span className="c1">For data portability requests, We will select a format to provide Your personal
                  information that is readily usable and should allow You to transmit the information from one entity to
                  another entity without hindrance.</span></p>
                <h2 className="c10"><span className="c4">Do Not Sell My Personal Information</span></h2>
                <p className="c9"><span className="c1">You have the right to opt-out of the sale of Your personal information. Once We
                  receive and confirm a verifiable consumer request from You, we will stop selling Your personal information.
                  To exercise Your right to opt-out, please contact Us.</span></p>
                <p className="c9"><span className="c1">The Service Providers we partner with (for example, our analytics or advertising
                  partners) may use technology on the Service that sells personal information as defined by the CCPA law. If
                  you wish to opt out of the use of Your personal information for interest-based advertising purposes and
                  these potential sales as defined under CCPA law, you may do so by following the instructions below.</span>
                </p>
                <p className="c9"><span className="c1">Please note that any opt out is specific to the browser You use. You may need to opt
                  out on every browser that You use.</span></p>
                <h3 className="c10"><span className="c2">Website</span></h3>
                <p className="c9"><span className="c1">You can opt out of receiving ads that are personalized as served by our Service
                  Providers by following our instructions presented on the Service:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c1">The NAI&#39;s opt-out platform: </span><span className="c1"><a
                    className="c11"
                    href="https://www.google.com/url?q=http://www.networkadvertising.org/choices/&amp;sa=D&amp;source=editors&amp;ust=1673855743423739&amp;usg=AOvVaw3O0bfiqa3BDv0NFT3PH0C0">http://www.networkadvertising.org/choices/</a></span>
                  </li>
                  <li className="c7 c5 li-bullet-0"><span className="c1">The EDAA&#39;s opt-out platform </span><span className="c1"><a
                    className="c11"
                    href="https://www.google.com/url?q=http://www.youronlinechoices.com/&amp;sa=D&amp;source=editors&amp;ust=1673855743424008&amp;usg=AOvVaw0LC3pA-dAhBZam9X5fsxQb">http://www.youronlinechoices.com/</a></span>
                  </li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c1">The DAA&#39;s opt-out platform: </span><span className="c1"><a
                    className="c11"
                    href="https://www.google.com/url?q=http://optout.aboutads.info/?c%3D2%26lang%3DEN&amp;sa=D&amp;source=editors&amp;ust=1673855743424277&amp;usg=AOvVaw0Q6wb05kxYH1Giix7toRUY">http://optout.aboutads.info/?c=2&amp;lang=EN</a></span>
                  </li>
                </ul>
                <p className="c9"><span className="c1">The opt out will place a cookie on Your computer that is unique to the browser You
                  use to opt out. If you change browsers or delete the cookies saved by your browser, You will need to opt out
                  again.</span></p>
                <h3 className="c10"><span className="c2">Mobile Devices</span></h3>
                <p className="c9"><span className="c1">Your mobile device may give You the ability to opt out of the use of information
                  about the apps You use in order to serve You ads that are targeted to Your interests:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c7 c5 li-bullet-0"><span className="c1">&quot;Opt out of Interest-Based Ads&quot; or &quot;Opt out of Ads
                    Personalization&quot; on Android devices</span></li>
                  <li className="c9 c12 c5 li-bullet-0"><span className="c1">&quot;Limit Ad Tracking&quot; on iOS devices</span></li>
                </ul>
                <p className="c9"><span className="c1">You can also stop the collection of location information from Your mobile device by
                  changing the preferences on Your mobile device.</span></p>
                <p className="c6 title"><span className="c14">&quot;Do Not Track&quot; Policy as Required by California Online Privacy
                  Protection Act (CalOPPA)</span></p>
                <p className="c9"><span className="c1">Our Service does not respond to Do Not Track signals.</span></p>
                <p className="c9"><span className="c1">However, some third party websites do keep track of Your browsing activities. If You
                  are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do
                  not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your
                  web browser.</span></p>
                <p className="c6 title"><span className="c14">Children&#39;s Privacy</span></p>
                <p className="c9"><span className="c1">Our Service does not address anyone under the age of 13. We do not knowingly collect
                  personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You
                  are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We
                  have collected Personal Data from anyone under the age of 13 without verification of parental consent, We
                  take steps to remove that information from Our servers.</span></p>
                <p className="c9"><span className="c1">If We need to rely on consent as a legal basis for processing Your information and
                  Your country requires consent from a parent, We may require Your parent&#39;s consent before We collect and
                  use that information.</span></p>
                <p className="c6 title"><span className="c14">Your California Privacy Rights (California&#39;s Shine the Light law)</span>
                </p>
                <p className="c9"><span className="c1">Under California Civil Code Section 1798 (California&#39;s Shine the Light law),
                  California residents with an established business relationship with us can request information once a year
                  about sharing their Personal Data with third parties for the third parties&#39; direct marketing
                  purposes.</span></p>
                <p className="c9"><span className="c1">If you&#39;d like to request more information under the California Shine the Light
                  law, and if You are a California resident, You can contact Us using the contact information provided
                  below.</span></p>
                <p className="c6 title"><span className="c14">California Privacy Rights for Minor Users (California Business and Professions
                  Code Section 22581)</span></p>
                <p className="c9"><span className="c1">California Business and Professions Code Section 22581 allows California residents
                  under the age of 18 who are registered users of online sites, services or applications to request and obtain
                  removal of content or information they have publicly posted.</span></p>
                <p className="c9"><span className="c1">To request removal of such data, and if You are a California resident, You can
                  contact Us using the contact information provided below, and include the email address associated with Your
                  account.</span></p>
                <p className="c9"><span className="c1">Be aware that Your request does not guarantee complete or comprehensive removal of
                  content or information posted online and that the law may not permit or require removal in certain
                  circumstances.</span></p>
                <p className="c6 title"><span className="c14">Links to Other Websites</span></p>
                <p className="c9"><span className="c1">Our Service may contain links to other websites that are not operated by Us. If You
                  click on a third party link, You will be directed to that third party&#39;s site. We strongly advise You to
                  review the Privacy Policy of every site You visit.</span></p>
                <p className="c9"><span className="c1">We have no control over and assume no responsibility for the content, privacy
                  policies or practices of any third party sites or services.</span></p>
                <p className="c6 title"><span className="c14">Changes to this Privacy Policy</span></p>
                <p className="c9"><span className="c1">We may update Our Privacy Policy from time to time. We will notify You of any changes
                  by posting the new Privacy Policy on this page.</span></p>
                <p className="c9"><span className="c1">We will let You know via email and/or a prominent notice on Our Service, prior to the
                  change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy
                  Policy.</span></p>
                <p className="c9"><span className="c1">You are advised to review this Privacy Policy periodically for any changes. Changes
                  to this Privacy Policy are effective when they are posted on this page.</span></p>
                <p className="c6 title"><span className="c14">Contact Us</span></p>
                <p className="c9"><span className="c1">If you have any questions about this Privacy Policy, You can contact us:</span></p>
                <ul className="c3 lst-kix_list_1-0">
                  <li className="c9 c12 c5 li-bullet-0"><span className="c1">By email: </span><span className="c17"><a className="c11"
                    href="mailto:support@wogd.com">info@dicefanatics.com</a></span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
