import React from 'react'

const Footer = ({sticky}) => {

    return (
        <div className={`pattern-dark ${sticky && `fixed-bottom`}`}>
            <footer class="container footer">
                <div class="row">
                    <div class="col-xs-12 col-sm-6">

                        <nav class="footer-nav">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/privacy&policy">Privacy & Policy</a></li>
                                <li><a href="/help&support">Help & Support</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div class="col-xs-12 col-sm-6">
                        <nav class="social-nav">
                            <a href="#"><span class="fab fa-facebook-f"></span></a>
                            <a href="#"><span class="fab fa-twitter"></span></a>
                            <a href="#"><span class="fab fa-youtube"></span></a>
                            <a href="#"><span class="fab fa-instagram"></span></a>
                        </nav>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 col-sm-6 d-flex justify-content-center">
                        <p className='description'>Contact us for more information by emailing <a className='email' href="mailto:jared@illusioni.st">jared@illusioni.st</a></p>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer