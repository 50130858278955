import MainLogo from "../assests/img/MainLogo.png"

const Breadcrumbs = () => {

  return (
    <>
      <div className="container-fluid bg-dark page-banner">
        <div className="row ">
          <div className="banner_content">
            <div className="d-flex justify-content-center ">
              <img
                class="big-logo mt-5"
                src={MainLogo}
                alt="Dice Fanatics Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Breadcrumbs;