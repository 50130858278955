import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/pages/Home";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import HelpSupport from "./components/pages/HelpSupport";
import NotFound from "./components/pages/404";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './App.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/privacy&policy" element={<PrivacyPolicy />} />
        <Route exact path="/help&support" element={<HelpSupport />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
