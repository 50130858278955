import React from 'react'
import navlogo from "../assests/img/miniLogo.png"
const Header = () => {
	return (
		<>
			<nav class="navbar navbar-inverse navbar-expand-lg navbar-custom sticky-top">
				<div class="container">
					<a class="navbar-brand" href="#">
						<img src={navlogo} alt="" class="nav-logo d-inline-block align-text-top" />
					</a>
					<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse justify-content-end nav-menu" id="navbarNav">
						<ul class="navbar-nav ">
							<li class="nav-item">
								<a class="nav-link " href="/">Home</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/privacy&policy">Privacy & Policy</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/help&support">Help & Support</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	)
}

export default Header