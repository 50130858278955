import React from 'react'
import Footer from '../Footer'
import Header from '../Header'

const NotFound = () => {
    return (
        <>
            <Header />
            <div className='d-flex justify-content-center privacy-policy'>
                <h1>404 Not found</h1>
            </div>
            <Footer sticky={true} />
        </>
    )
}

export default NotFound