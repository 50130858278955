import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import Footer from '../Footer'
import Header from '../Header'

const Home = () => {
    return (
        <>
            <Header />
            <Breadcrumbs />
            <div className='subscribe-div'>
                <h2 className="title">UNIQUE AND COLLECTIBLE DICE FROM GLOBAL ARTISANS</h2>

                <p className='desc'>We travel the world in search of unique and collectible dice. Including all of the standard, known dice manufacturers, we strive to find and support artisans all over the planet who make handmade dice and dice accessories. Purchasing dice from Dice Fanatics is also supporting those individual artisans who work hard to create new and interesting dice for you to enjoy!</p>

                <h2 className="title mt-3">BECOME A DICE FANATIC!</h2>

                <div className="email mb-3">
                    <div class="input-group w-25">
                        <input type="text" className="form-control" placeholder="email address" aria-label="Example text with button addon" aria-describedby="button-addon1" />
                    </div>
                    <button type="button" className="btn btn-subscribe">Subscribe</button>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Home